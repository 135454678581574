.cardGameWindow{
    color: aliceblue;
    position: fixed;
    bottom: 28vw; /* <--- set to zero, 1em = the inherited font size, which will offset it */
    left: 2vw;/* <--- set to zero, 1em = the inherited font size, which will offset it */
    cursor: pointer;
    z-index: 1000;
    min-width: 250px;
    max-width: 500px;
}

.cardAnimation{
    width: 350px;
}

.cardButton{
    max-width: 250px;
    margin: auto;
    width: 300px;
}

.cardButton{
    max-width: 225px;
    /* max-height: 50px; */
    margin: auto;
    font-size: 0.75em;
    font-family: 'VT323', monospace;
}

.hudText{
    font-size: 2em;
    font-family: 'VT323', monospace;
    color: lightgreen;
}

.hud{
    padding: 5px;
    width: auto;
    height: 100px;
    margin-bottom: 20px;
}