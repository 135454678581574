@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900);
* {
  outline: none !important;
}

body {
  --blue: #007bff;
  --z: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto Mono", monospace;
  --font-family-monospace: "Roboto Mono", monospace;
  margin: 0;
  font-family: "Roboto Mono", monospace !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #FFF;
  text-align: left;
  background-color: #000 !important;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cardGameWindow{
    color: aliceblue;
    position: fixed;
    bottom: 28vw; /* <--- set to zero, 1em = the inherited font size, which will offset it */
    left: 2vw;/* <--- set to zero, 1em = the inherited font size, which will offset it */
    cursor: pointer;
    z-index: 1000;
    min-width: 250px;
    max-width: 500px;
}

.cardAnimation{
    width: 350px;
}

.cardButton{
    max-width: 250px;
    margin: auto;
    width: 300px;
}

.cardButton{
    max-width: 225px;
    /* max-height: 50px; */
    margin: auto;
    font-size: 0.75em;
    font-family: 'VT323', monospace;
}

.hudText{
    font-size: 2em;
    font-family: 'VT323', monospace;
    color: lightgreen;
}

.hud{
    padding: 5px;
    width: auto;
    height: 100px;
    margin-bottom: 20px;
}
 html {
   height: 100%;
   overflow: hidden;
}
